<template>
	<div class=''>
		<mzSwiper></mzSwiper>
		<!-- 导航栏 -->
		<div class="flex flex-wrap row-between">
			<div :class="['nav_item mb5',i==active?'nav_item_avtive':'']" v-for="i in 4" :key="i">分类</div>
		</div>
		<!-- //导航栏 -->
		<!-- 内容区域 -->
		<contentItem />
	</div>
</template>

<script>
	export default {
		data() {
			return {
				active: 1
			};
		},

		components: {},

		created() {},

		mounted() {},

		methods: {}
	}
</script>
<style lang='scss' scoped>
	.nav_item,
	.nav_item_avtive {
		width: 49.3%;
		height: 44px;
		background: #F2F2F2;
		font-size: 12px;
		font-weight: 500;
		color: #787878;
		line-height: 44px;
		text-align: center;
		font-weight: 500;
	}

	.nav_item_avtive {
		color: #000000 !important;
		background: #F7B500 !important;
	}
</style>
